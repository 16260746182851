import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { mq } from '../../utils/style'
import { FlexColumn, FlexRow, HomeBlock, HomeBlockContent } from '../styled'

export const iconSize = 1.5

export const HomeBlockFiles = styled(HomeBlock)`
  position: relative;
  overflow-x: hidden;

  ${(props) => mq(props)({
  padding: ['15px', `0`],
  textAlign: [props.centerOnMobile ? 'center' : 'left', 'left'],
})}
`;

export const HomeBlockFilesContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  
  &.slideInRight {
    ${(props) =>
    mq(props)({
      animation: ['slideRight 1s', 'none'],
    })}
    ;
  }

  &.slideInLeft {
    ${(props) =>
    mq(props)({
      animation: ['slideLeft 1s', 'none'],
    })}
    ;
  }

  @keyframes slideRight {
    0% { transform: translateX(100%); }
    100% { transform: translateX(0); }
  }

  @keyframes slideLeft {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
  }
  @media (max-width: 1024px) {
    overflow: auto;
    padding-right: 5px;
  }
`;

export const HomeBlockContentFiles = styled(HomeBlockContent)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 65px);
  & :hover {
    overflow-y: overlay;
  }
  @-moz-document url-prefix() {
    overflow-y: auto;
  }

  ${(props) => mq(props)({
  padding: ['0 4px', '0 16px 20px 20px'],
  gap: [props.isFolderExpanded ? 0 : '16px', 0]
})}
`;

export const File = styled('button')`
  display: flex;
  padding: 0;
  align-items: center;
  flex: 1 0;
  border: none;
  background-color: transparent;
  outline: none;
  height: 25px;

  &:nth-of-type(even) {
    background-color: ${(props) => props.theme.colors.light};
  }

  &[data-isFolder=true] {
    font-weight: 500;
  }

  font-size: 1em;
`

export const FileContents = styled(FlexRow)`
  height: max-content;
  border-radius: ${({ isOpened }) => isOpened ? '5px 5px 0 0' : '5px'};
  flex-shrink: 0;
  align-items: center;
  margin: 0;

  ${(props) => mq(props)({
  padding: [0, '12px 20px'],
})}

  &:hover {
    background-color: ${({ disabled, theme }) => disabled ? 'initial' : theme.colors.overOrSelected};
  }

  ${File} {
    ${(props) => mq(props)({
  paddingTop: [0, '12px'],
  paddingBottom: [0, '12px'],
})}
  }

  &.highlight {
    background-color: ${({ theme }) => theme.colors.overOrSelected};
  }

  @media (max-width: 768px) {
    padding: 5px;
  }
`

export const FileIcon = styled(FlexRow)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    mq(props)({
      width: props.isFolder ? '27px !important' : ['13px'],
      height: props.isFolder ? '20px !important' : ['17px'],
      marginLeft: props.isFolder
        ? `-17px`
        : ['23.5px', `${props.theme.spacings.medium}px`]
    })}

  svg {
    ${(props) =>
    mq(props)({
      color: props.theme.colors.primary,
      width: '100% !important',
      height: '100% !important',
    })}
  }
  @media (max-width: 768px) {
    margin-left: -5px !important;
  }
`

export const FolderArrowIcon = styled(FlexRow)`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const FileName = styled('p')`
  border: none;
  margin-left: ${({ isFolder }) => isFolder ? '8px' : '12px'};
  background-color: transparent;
  color: ${({ isPlaceholder, theme }) => (isPlaceholder ? '#ccc' : theme.colors.primary)};
  font-style: ${({ isPlaceholder }) => (isPlaceholder ? 'italic' : 'normal')};
  text-align: left;
  flex: 1;
  font-size: ${({ isFolder }) => isFolder ? '1em' : '0.75em'};
  line-height: 1;
  font-family: ${({ theme }) => theme.fonts.workSans};
  font-weight: ${({ isFolder }) => isFolder ? 500 : 400};
  max-width: ${({ isFolder }) => isFolder ? '100%' : '114px'};
  white-space: ${({ isFolder }) => isFolder ? 'unset' : 'nowrap'};
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 1025px) {
    font-size: ${({ isFolder }) => isFolder ? '14px' : '12px'};
    line-height: 1.1875em;
    max-width: unset;
  }
`

export const fileButtonCss = () => css`
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 2px;
  cursor: pointer;

  ${FileContents}:hover > & {
    visibility: visible;
  }
`

export const ActionButton = styled.button`
  ${fileButtonCss}

  ${(props) => mq(props)({
  padding: ['7.14px', 0]
})}

  & svg {
    ${(props) => mq(props)({
  width: ['15.75px !important', '16px !important'],
  height: ['15.75px !important', '16px !important']
})}
  }
`

export const FileButton = styled(ActionButton)`
  ${(props) => mq(props)({
  backgroundColor: ['rgba(255, 81, 81, 0.15)', 'transparent'],
  margin: 0
})}
`

export const FileLink = styled(ActionButton)`
  ${(props) => mq(props)({
  backgroundColor: ['rgba(0, 21, 86, 0.15)', 'transparent'],
  margin: props.showDeleteBtn === 'true' ? ['0 8px 0 0', '0 12px 0 0'] : 0,
})}
`

export const FolderContents = styled(FlexColumn)`
  flex-shrink: 0;
  overflow: hidden;
  width: 100%;

  ${FileContents}:hover + & {
    background-color: ${({ theme }) => theme.colors.overOrSelected};
    border-radius: 0 0 5px 5px;
  }
`

export const FolderContentsWrapper = styled(FlexColumn)`
  ${(props) => mq(props)({
  padding: ['0', `0 calc(${iconSize / 2}em + ${(props) => props.theme.spacings.small}px) 8px`]
})}

  ${FileContents}:hover + ${FolderContents} > & {
    border-left-color: transparent;
  }

  ${FileContents} {
    ${(props) => mq(props)({
  padding: [0, '6px 20px 6px 40px'],
  margin: ['0 0 8px', '0 0 0 0px'],
  width: ['100%', 'calc(100% - 0px)']
})}
  }
`

export const DateWrapper = styled('div')`
  margin-right: 9px;
  font-weight: 300;
  font-size: 0.625em;
  color: ${({ theme }) => theme.colors.primary};

  @media (min-width: 1025px) {
    margin-right: 16px;
    font-size: 0.875em;
  }
`

export const HiddenMobile = styled('div')`
  @media (max-width: 768px) {
    display: none !important;
  }
`
