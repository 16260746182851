import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  projectWorkTypesSelector,
  isBtoBSelector,
  isAlgarDeskSelector
} from '../../selectors'
import { getProjectStatus } from '../page-project-list/helpers'
import { HomeBlock } from '../styled'
import {
  StatusBadge,
  StatusHomeBlockContent,
  StatusHomeBlockTitle,
  StatusIndicator
} from './styled'

function StatusContainer({ project }) {
  const isBtoB = useSelector(isBtoBSelector)
  const isDesk = useSelector(isAlgarDeskSelector)
  const projectStatus = getProjectStatus(
    project.dealstage,
    isBtoB,
    isDesk,
    project.pipeline
  )

  return (
    <HomeBlock centerOnMobile>
      <StatusHomeBlockTitle>Statut</StatusHomeBlockTitle>
      <StatusHomeBlockContent>
        <StatusBadge
          colors={{
            color: projectStatus.color,
            bgColor: projectStatus.bgColor || '#FFF'
          }}
        >
          {projectStatus.title}
        </StatusBadge>
        <StatusIndicator>
          {projectStatus.message || ''}
          {projectStatus.title === 'Panneau expédié' && (
            <a href={
              "https://trace.dpd.fr/fr/trace/"  +
                project.numeroColisPanneau
            }
              >Suivez sa livraison ici.</a>
              )}
        </StatusIndicator>
      </StatusHomeBlockContent>
    </HomeBlock>
  )
}

StatusContainer.propTypes = {
  project: PropTypes.object.isRequired
}

export default StatusContainer