import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { projectWorkTypesSelector } from '../../selectors'
import DetailsView from '../view-details'

const optional = (obj) => (obj ? [obj] : [])

class DetailsContainer extends Component {
  render() {
    const { project, projectWorkTypes } = this.props

    const details = [
      {
        id: 1,
        title: 'Nom du dossier',
        content:
          project.name ||
          project.projectname ||
          project.dealname
      },
      {
        id: 2,
        title: 'Localisation',
        content: project.address
      },
      {
        id: 3,
        title: 'Types de projets',
        content: projectWorkTypes && projectWorkTypes.length > 0
          ? projectWorkTypes
            .map((worktype) => worktype.name[0].toUpperCase() + worktype.name.slice(1))
            .join(', ')
          : "À définir lors de l'appel de découverte"
      },
      ...optional({
        id: 4,
        title: 'Numéro de dossier',
        content: project.numeroInstruction
      })
    ]

    return <DetailsView details={details} />
  }
}

DetailsContainer.propTypes = {
  project: PropTypes.object,
  projectWorkTypes: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  projectWorkTypes: projectWorkTypesSelector(state)
})

export default connect(mapStateToProps, null)(DetailsContainer)
