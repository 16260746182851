import { faSquareCheck, faSquare } from '@fortawesome/pro-solid-svg-icons'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { isAlgarDeskSelector, isBtoBSelector } from '../../selectors'
import { stepsSelector } from '../../selectors/step'
import { formatDate } from '../../utils/date'
import {
  Square,
  SquareContainer,
  SquareIcon,
  StepNameV2,
  StepsWrapper,
  StepsWrapperContent,
  StepsWrapperTitle,
  StepV2
} from './styled'

const stepsWrapperContentId = '__Steps__Wrapper__Content__Id__'

const StepContainer = ({
  step,
  steps,
  isLast,
  wrapperHeight,
  stepsLength,
  nextStep,
  index
}) => {
  const projectSteps = useSelector(stepsSelector)

  if (projectSteps === [] || null || undefined || projectSteps.length === 0){
    projectSteps.push({
      "name": "error pas de suivis",
      "metaCode": "error_not_defined"
    })
  }

  let lastStep = projectSteps[projectSteps.length - 1]
  const resultStep = []
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].code === lastStep.metaCode) {
      for (let j = 0; j < i; j++) {
        resultStep.push({
          "name": steps[j].name,
          "metaCode": steps[j].code
        })
      }
      break
    }
  }
  resultStep.push({
    "name": lastStep.name,
    "metaCode": lastStep.metaCode
  });



  const projectStepCodes = resultStep.map(({ metaCode }) => metaCode)

  const lineHeight = wrapperHeight / stepsLength - 7

  const completedStep = resultStep.find(
    ({ metaCode }) => metaCode === step.code
  )

  const isCompleted = completedStep && completedStep.metaCode === step.code
  const isCompletedNext = nextStep && projectStepCodes.includes(nextStep.code)

  return (
      <Fragment>
        <StepV2>
          <StepNameV2>
            <p className="title">{`${index + 1}. ${step.name}`}</p>
            {/* <p className="date">{formatDate(completedStep?.createdAt)}</p> */}
          </StepNameV2>
          <SquareContainer
              isLast={isLast}
              lineHeight={lineHeight}
              isCompleted={nextStep ? isCompletedNext : isCompleted}
              done={isCompleted}
          >
            {(isCompleted || isCompletedNext) ? <SquareIcon icon={faSquareCheck} /> : <SquareIcon icon={faSquare} />}
          </SquareContainer>
        </StepV2>
      </Fragment>
  )

}

const stepPropTypeShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired
}

StepContainer.propTypes = {
  step: PropTypes.shape(stepPropTypeShape).isRequired,
  nextStep: PropTypes.shape(stepPropTypeShape),
  wrapperHeight: PropTypes.number.isRequired,
  stepsLength: PropTypes.number.isRequired
}

const ViewSteps = ({ steps }) => {
  const isBtoB = useSelector(isBtoBSelector)
  const isDesk = useSelector(isAlgarDeskSelector)
  const [stepsWrapperContentHeight, setStepsWrapperContentHeight] = useState(0)

  React.useEffect(() => {
    const el = document.getElementById(stepsWrapperContentId)
    if (el) setStepsWrapperContentHeight(el.scrollHeight)
  }, [])

  return (
      <>
        <StepsWrapperTitle>Suivi</StepsWrapperTitle>
        <StepsWrapperContent btobStepper={isBtoB} deskStepper={isDesk} id={stepsWrapperContentId}>
          {steps.map((step, index) => {
            return (
                <Fragment key={`step-${step.id}`}>
                  <StepContainer
                      step={step}
                      steps={steps}
                      nextStep={steps[index + 1]}
                      isLast={index + 1 >= steps.length}
                      wrapperHeight={stepsWrapperContentHeight}
                      stepsLength={steps.length}
                      index={index}
                  />
                </Fragment>
            )
          })}
        </StepsWrapperContent>
      </>
  )
}

ViewSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape(stepPropTypeShape).isRequired).isRequired
}

export default ViewSteps
