import styled from '@emotion/styled'
import { HomeBlock, HomeBlockTitle } from '../styled'

export const MyActionsStyled = styled(HomeBlock)`
  width: 100%;
  overflow: hidden;
  padding-bottom: 25px;
  padding-right: 0;

  @media screen and (max-width: 768px) {
    overflow: auto;
    padding-bottom: 15px;
  }
`

export const MyActionsTitle = styled(HomeBlockTitle)`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 21px;
  padding-bottom: 0;
  @media (max-width: 768px){
    text-align: center;
    margin-bottom: 10px;
  } 
`

export const MyActionsContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  gap: 15px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-bottom: 6px;
  line-height: normal;
  padding-right: 25px;

  & :hover {
    overflow-y: overlay;
  }
  @-moz-document url-prefix() {
    overflow-y: auto;
    padding-right: 18px;
  }

  @media (max-width: 1024px) {
    overflow: auto;
    padding-right: 5px;
  }
`
