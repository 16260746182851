import { css } from '@emotion/core'
import styled from '@emotion/styled'
import ReactPaginate from 'react-paginate'
import { textFontSizeCss } from '../../styles/common'
import { mq } from '../../utils/style'
import { BaseInputStyle } from '../overlay-create-project/styled'

export const bigScrollbarClass = '__class__big__scrollbar'

const flexCenter = () => css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ProjectListWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${(props) =>
    mq(props)({
      padding: ['15px', '40px']
    })}
`

export const ProjectsListContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 85%;
  overflow-y: auto;

  margin: 0;
`

export const BtnScrollHorizontal = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ position }) => (position === 'right' ? '13px' : 'unset')};
  right: ${({ position }) => (position === 'left' ? '13px' : 'unset')};
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};

  & > svg {
    width: 26px !important;
    height: 26px !important;
    color: ${({ theme }) => theme.colors.primarySemiLight};
  }

  & > svg:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 768px) {
    display: none;
  }
`

export const BtnScrollHorizontalCards = styled(BtnScrollHorizontal)`
  left: ${({ position }) => (position === 'right' ? '53px' : 'unset')};
  right: ${({ position }) => (position === 'left' ? '53px' : 'unset')};
  top: 100px;
  @media (min-width: 1668px) {
    display: none;
  }
`

export const MainTitle = styled('div')`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4D5B89;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
  height: 70px;
  font-size: 18px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 5px 5px 0 0;

  & > svg {
    width: 17px;
    height: 17px;
    margin-right: 7px;
  }
`

export const SubTitle = styled.div`
  ${textFontSizeCss}
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  padding: 0 15px;

  @media (min-width: 769px) {
    padding: 0;
  }
`

export const SubTitleText = styled.div`
  ${textFontSizeCss}
  font-weight: 300;
  line-height: 16px;
  text-align: center;
  margin-top: 8px;
`

export const ProjectContainer = styled.div`
  width: 100%;
  position: relative;
  border: ${({ isMobile }) => isMobile ? 'none' : `1px solid ${({ theme }) => theme.colors.primary}`};
  border-radius: 5px;
  margin-top: ${({ isMobile }) => (isMobile ? 20 : 0)}px;
  height: 100%;
  min-height: 90px;
  overflow: scroll;
  padding: 0;
  background-color: #fff;

  ::-webkit-scrollbar {
    width: 0px;
    height: 6px;
  }

  &.${bigScrollbarClass}:hover::-webkit-scrollbar {
    height: 10px;
  }

  @media (min-width: 769px) {

    &:hover {
      overflow: overlay;
    }
  }
`

export const ProjectListContainer = styled.table`
  width: 100%;
  border-collapse: unset;
  overflow-x: scroll;
  overflow-y: scroll;
  font-size: 14px;
  line-height: 16px;
`

export const ProjectTitle = styled('tr')`
  line-height: 1.5;
`

export const ProjectTableHead = styled('thead')`
  border-bottom: 1px solid lightgrey;
  position: sticky;
  top: 0;
  z-index: 1;
  color: white;
  background-color: #4D5B89;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
  height: 45px;
  font-size: 18px;
  width: 100%;
  border-radius: 5px 5px 0 0;
`

const projectInfoPaddingCss = () => css`
  padding: 0px 25px;

  &:nth-of-type(1) {
    padding-right: 0;
    padding-left: 10px;
  }

  &:nth-last-of-type(1) {
    padding-right: 25px;
  }

  @media (max-width: 768px) {
    &:nth-of-type(1) {
      padding-left: 15px;
    }

    &:nth-last-of-type(1) {
      padding-right: 15px;
    }
  }
`

export const ProjectInfoTitle = styled.th`
  ${projectInfoPaddingCss}
  padding: 5px 15px;
  text-align: left;
  font-weight: 500;
  ${textFontSizeCss}
  line-height: 14px;
  vertical-align: middle;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const ProjectLine = styled('tr')`
  height: 35px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.overOrSelected};
  }

  &.selectedProject {
    background-color: ${({ theme }) => theme.colors.overOrSelected};
    @media screen and (max-width: 1160px) {
      height: auto;
    }
  }
`

export const ProjectInfo = styled.td`
  ${projectInfoPaddingCss}
  text-align: left;
  overflow: hidden;
  vertical-align: middle;
  font-weight: 300;
  ${textFontSizeCss}
  line-height: 14px;
`

export const ProjectInfoNameContainer = styled(ProjectInfo)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: auto;
  padding: 0;

  @media (min-width: 368px) {
    min-width: 134px;
  }

  @media (min-width: 769px) {
    min-width: max-content;
    width: 3%;
  }
`

export const desktopTdCss = () => css`
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const DesktopTd = styled(ProjectInfo)`
  ${desktopTdCss}
`

export const ProjectInfoName = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  width: 100%;
  position: relative;
  ${desktopTdCss}
`

export const DateTd = styled(DesktopTd)``

export const mobileTdCss = () => css`
  @media screen and (min-width: 769px) {
    display: none;
  }
`

export const ProjectInfoNameMobile = styled.span`
  ${mobileTdCss}
`

export const ProjectInfoWorkType = styled(DesktopTd)`
  overflow: hidden;
  width: 28.5%;
  max-width: 300px;

  & > span {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 31px;
    overflow: hidden;
  }
`

export const ProjectInfoAdress = styled(DesktopTd)`
  width: 35%;

  span {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 31px;
    overflow: hidden;
  }
`

export const StatusTh = styled(ProjectInfoTitle)`
  @media (max-width: 768px) {
    padding: 12.5px 10px;
  }
`

export const DesktopTh = styled(ProjectInfoTitle)`
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
export const InstrucTH = styled(ProjectInfoTitle)`
  font-size: 16px;
  min-width: 240px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const DesktopInstructionTh = styled(DesktopTh)`
  min-width: 163px;
  position: relative;
  & span:last-of-type {
    position: absolute;
    bottom: 10px;
    right: 39px;
    @media (max-width: 768px) {
      right: 0;
    }
  }
`

export const FolderNameTh = styled(ProjectInfoTitle)`
  padding: 5px 0;
  @media screen and (min-width: 769px) {
    min-width: 262px;
  }
`

export const FolderTypeTh = styled(DesktopTh)`
  @media screen and (min-width: 769px) {
    min-width: 191px;
  }
`

export const AddressTh = styled(DesktopTh)`
  @media screen and (min-width: 769px) {
    min-width: 286px;
  }
`

export const InfoSuppTh = styled(ProjectInfoTitle)`
  padding: 0;
  @media screen and (min-width: 769px) {
    display: none;
  }
`

export const DateCreationTh = styled(DesktopTh)`
  min-width: 100px;
`

export const DateDepotTh = styled(DesktopTh)`
  min-width: 118px;
`

export const DesktopThWithTooltip = styled(DesktopTh)`
  & > span:nth-of-type(1) {
    margin-right: 5px;
  }
`

export const DateFinInstructionTh = styled(DesktopThWithTooltip)`
  min-width: 163px;
  position: relative;
  & span:last-of-type {
    position: absolute;
    bottom: 10px;
    right: 68px;
    @media (max-width: 768px) {
      right: 0;
    }
  }
`

export const DateReceptionDpcTh = styled(DesktopThWithTooltip)`
  min-width: 138px;
  position: relative;
  & span:last-of-type {
    position: absolute;
    bottom: 10px;
    right: 27px;
  }
`

export const ButtonCancel = styled('a')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 30px;
  padding: 5px;
  margin: 10px 0px 10px 10px;
  border-radius: 2px;
  border: none;
  background-color: ${(props) => props.theme.colors.dark}60;
  color: white;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.workSans};
  ${(props) =>
    props.hovered &&
    `&:hover{
        background-color:${props.theme.colors.dark}80;
      }`}
  @media screen and (min-width: 769px) {
    width: 107px;
    height: 37px;
  }
`

export const ValidateButtonDisabled = styled('button')`
  font: unset;
  background-color: ${(props) => props.theme.colors.primarySelected};
  width: 70px;
  padding: 8px 10px;
`

export const ButtonsWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  align-self: center;

  @media screen and (min-width: 769px) {
    margin: 10px 0 0;
    align-self: center;
  }
`

export const ValidateButton = styled.button`
  width: 115px;
  position: absolute;
  right: 0px;
  transform: translateY(-50%);
  bottom: -5px;

  margin: 0;
  height: 44px;
  border-radius: 2px;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  ${textFontSizeCss}
  line-height: 18px;
  font-family: ${({ theme }) => theme.fonts.workSans};
  font-weight: 600;
  align-self: centered;

  @media (max-width: 768px) {
    width: fit-content;
    padding: 0 15px;
    width: 81px;
    height: 34px;
    position: inherit;
    flex-shrink: 0;
    margin: 0 auto;
    right: 0;
    transform: unset;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary}50;
  }
`

export const SingleProjectText = styled.p`
  display: flex;
  justify-content: center;
  font-weight: 300;
  margin: 5% 0;
  margin-top: 10%;
  margin-bottom: 1rem;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.workSans};
  color: ${(props) => props.theme.colors.medium};
  text-align: center;
`

export const NoResultText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 108px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #bbbbbb;

  & > span {
    margin-left: 5px;
  }

  & > svg {
    width: 18px;
    height: 18px;
  }
`

export const ProjectInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  & > span {
    display: flex;
    align-items: center;
    line-height: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
    height: 100%;

    @media (min-width: 1200px) {
      max-width: 264px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`

export const ButtonCreateProjectContainer = styled.div`
  width: max-content;
  display: none;
  justify-content: flex-end;

  @media (max-width: 768px) {
    grid-area: ButtonCreateProjectContainer;
  }
`

const OutlineBtnCss = (props) => css`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 0.5px solid ${props.theme.colors.primary};
  border-radius: 2px;
  background-color: ${props.theme.colors.white};
  color: ${props.theme.colors.primary};
  font-family: ${props.theme.fonts.workSans};
  font-weight: 400;
  line-height: 16px;
  padding: 0;
  outline: none;

  &:focus {
    outline: none;
  }

  & > svg {
    width: 20px !important;
    height: 20px !important;
  }

  & :hover {
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.primary};
  }
`

export const ButtonCreateProject = styled.button`
  ${OutlineBtnCss}
  ${textFontSizeCss}
  width: max-content;
  height: 40px;
  padding: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const ButtonCreateProjectMobile = styled.div`
  display: none;

  & > svg {
    width: 30px !important;
    height: 30px !important;
  }

  @media (max-width: 768px) {
    display: block;
  }
`

export const SearchAndFilterContainer = styled.div`
  position: relative;
  width: max-content;
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    grid-area: SearchAndFilterContainer;
    width: fit-content;
  }
`

export const SearchInputContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;

  & > svg {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const SearchInput = styled.input`
  ${BaseInputStyle}
  ${textFontSizeCss}
  padding: 6.5px 7px;
  border: 0.5px solid ${({ theme }) => theme.colors.primary};
  border-radius: 3px;
  width: auto;
  height: 30px;
  width: 210px;
  background-color: inherit;

  @media (min-width: 414px) {
    width: 220px;
  }

  @media (min-width: 1500px) {
    width: 250px;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const ButtonFilterContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`

export const ButtonFilter = styled.button`
  ${OutlineBtnCss}
  ${textFontSizeCss}
  width: 85px;
  height: 30px;

  color: ${({ active, theme }) => (active ? theme.colors.white : 'inherit')};
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary : 'inherit'};

  & > svg {
    width: 13px !important;
    height: 15px !important;
  }

  @media (max-width: 768px) {
    width: 60px;

    & > svg {
      display: none;
    }
  }
`

export const ButtonDownload = styled.button`
  ${OutlineBtnCss}
  ${textFontSizeCss}
  width: 120px;
  height: 30px;
  padding: 0 10px;
  background-color: inherit;

  & > svg {
    width: 15px !important;
    height: 15px !important;
  }
`

export const FilterMenu = styled.div`
  position: absolute;
  top: 32px;
  z-index: 2;
  width: 205px;
  height: 205px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 4px 2px rgba(0, 21, 86, 0.12);
  border-radius: 3px;
`

export const ProjectStatusWrap = styled(ProjectInfo)`
  width: 12%;
  min-width: 115px;
  padding: 12px;

  @media (min-width: 769px) {
    min-width: 253px;
    padding: 12.5px 25px;
  }
`

export const ProjectStatus = styled.div`
  color: ${({ statusColor }) => statusColor || '#C4C4C4'};
  background-color: ${({ statusBgColor }) => statusBgColor || 'rgba(196, 196, 196, 0.15)'};
  height: auto;
  width: fit-content;
  padding: 10px;
  font-weight: 400;
  line-height: 9px;

  @media (min-width: 769px) {
    font-size: 14px;
    line-height: 14px;
  }

  @media (max-width: 768px) {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 12px;
    width: 90px;
    height: 30px;
    padding: 5px 10px;
  }

  @media (max-width: 280px) {
    font-size: 8px;
  }
`

export const ProjectStatusDesk = styled(ProjectStatus)``

export const CancelSearch = styled.span`
  margin-left: -15px;
  margin-right: 5px;
  & > svg {
    cursor: pointer;
  }
`

export const ModalFooterContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 77px;
  border-top: 1px solid #EDE8DD;
  @media (max-width: 1024px) {
    margin-top: 10px;
    flex-direction: column-reverse;
    width: 0;
  }
`

export const FooterContainerMobile = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ showPagination }) => showPagination ? 'flex-end' : 'center'};
  margin-top: 10px;

  & > ul {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }

  @media (max-width: 1024px) {
    margin-top: 20px;
    flex-direction: column-reverse;
    & > ul {
      position: inherit;
      margin-bottom: 10px;
      left: 0;
      transform: unset;
    }
  }

  @media (max-width: 370px) {
    justify-content: center;
    gap: 10px;

    & > ul {
      position: relative;
      left: unset;
      transform: unset;
      height: auto;
    }
  }
`

export const PaginationContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    position: inherit;
  }
`

export const ReactPaginateStyled = styled(ReactPaginate)`
  ${flexCenter}
  width: 100%;
  gap: 2px;

  & .pagination__prev,
  & .pagination__next,
  & .pagination__page {
    ${flexCenter}
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;

    & > a {
      ${flexCenter}
      width: 100%;
      height: 100%;
    }
  }

  & .is__active {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }

  & .is__disable {
    cursor: default;
    color: ${({ theme }) => theme.colors.greySemiLight};
  }

  @media (max-width: 768px) {
    max-width: 65vw;
  }
`

export const TableBody = styled.tbody`
  position: relative;
`

export const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const FilterContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`

export const FilterContainerMobile = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`
export const DetailsProjectWrap = styled(ProjectInfo)`
  @media (min-width: 769px) {
    display: none;
  }
`

export const DetailsProject = styled.div`
  width: 54px;
  height: 22px;
  font-size: 14px;
  line-height: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.light};
  }

  @media (max-width: 280px) {
    width: 40px;
    height: 19px;
    font-size: 8px;
    line-height: 9px;
  }
`
export const FilterSelectors = styled.div`
  margin-bottom: 30px;
  display: flex;
  gap: 20px;
  overflow: scroll;
  & ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`

export const FilterBox = styled.div`
  width: 207px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.backgroundColor};
  border-radius: 5px;
  padding: 10px;
  flex-shrink: 0;
  min-height: 120px;
  cursor: ${props => props.canBeFiltered && 'pointer'};
`
export const FilterTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.textColor};
`

export const FilterSubTitle = styled.p`
  font-size: 12px;
  margin-top: 5px;
  font-weight: 500;
  color: ${props => props.theme.colors.primary};
`

export const FilterCount = styled.p`
  font-size: 28px;
  font-weight: 600;
  color: ${props => props.textColor};
`

export const ButtonFilterActions = styled.button`
  ${textFontSizeCss}
  ${OutlineBtnCss}
  width: 170px;
  border: 1px solid #FF5151;
  background-color: ${props => props.active ? '#FF5151' : 'inherit'};
  color: ${props => props.active ? 'white' : '#FF5151'};
  display: flex;
  justify-content: center;
  & > span {
    border: 1px solid ${props => props.active ? 'white' : '#FF5151'};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & :hover {
    background-color: ${props => props.active ? 'inherit' : '#FF5151'};
    color: ${props => props.active ? '#FF5151' : 'white'};
    & > span {
      border: 1px solid ${props => props.active ? '#FF5151' : 'white'};
    }
  }
`
export const FilterIcon = styled.div`
 & > svg {
   width: 24px;
   height: 24px;
   color: ${props => props.isHovered ? '#00155650' : props.theme.colors.primary};
 }
`
export const FilterInfos = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  align-items: center;
  height: 32px;
`